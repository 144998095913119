import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import styles from './About.module.css'

const redditMsg = <a href="https://www.reddit.com/message/compose/?to=Pewqazz" rel="noreferrer noopener" target="_blank">Reddit</a>
const bluesky = <a href="https://bsky.app/profile/kevinyap.ca" rel="noreferrer noopener" target="_blank">Bluesky</a>

const About = () => (
  <div className={ styles.about }>
    <Helmet><title>About | zeal.gg</title></Helmet>
    <div>
      <p>
        <strong>zeal.gg</strong> is a site that allows League of Legends players to create
        customized leaderboards to track the solo queue climb with their friends (or rivals).
      </p>

      <p>
        To create a leaderboard, input a list of summoners on the <Link to="/">home page</Link>;
        this will bring you to a page with a unique, shareable URL. Pressing the &quot;refresh&quot;
        button on the leaderboard page will update the ranks of the summoners.
      </p>

      <p>
        You can also &quot;star&quot; a leaderboard, which will add a shortcut to the home page.
      </p>

    </div>

    <div>
      <h3>Why are leaderboards capped at 12 people?</h3>

      <p>
        With too many accounts, the charts become a mess to read and take too long to refresh.
      </p>
    </div>

    <div>
      <h3>Why do the charts only go back to a certain date?</h3>

      <p>
        Riot&#39;s API only allows you to check someone&#39;s current rank. In order to collect
        historical data, an explicit refresh needs to be performed from the site. You
        can <Link to="/patreon">support the site on Patreon</Link> to have your leaderboard automatically
        refresh on the hour!
      </p>
    </div>

    <div>
      <h3>Why does <strong>zeal.gg</strong> not have <em>X</em>?</h3>

      <p>
        There are a lot of features that would be nice, but my goal is to keep the site as minimalistic
        as possible. While I don&#39;t have much time to dedicate to development
        anymore, feedback is always welcome, preferably via {bluesky} or { redditMsg }!
      </p>
    </div>
  </div>
)

export default About
