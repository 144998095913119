import React, { Component } from 'react'
import PropTypes from 'prop-types'
import posthog from 'posthog-js'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faReddit } from '@fortawesome/free-brands-svg-icons'
import { faPatreon } from '@fortawesome/free-brands-svg-icons'
import { Tooltip } from 'react-tippy'

import About from './About'
import Header from './Header'
import Landing from './Landing'
import Leaderboard from './Leaderboard'
import Patreon from './Patreon'
import Summoner from './Summoner'

import styles from './App.module.css'

// eslint-disable-next-line no-extend-native
String.prototype.toTitleCase = function() {
  // Implementation via https://stackoverflow.com/a/196991
  return this.replace(/\w\S*/g, (s) => (
    s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  ))
}

const Disclaimer = () => (
  <div className={ styles.disclaimer }>
    <strong>zeal.gg</strong> isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games or anyone officially involved in producing or managing Riot Games properties. Riot Games, and all associated properties are trademarks or registered trademarks of Riot Games, Inc.
  </div>
)

const FooterIcon = ({ icon, text, link, internal, isBluesky }) => {
  let faIcon
  
  // Dear future Kevin,
  //
  // You're probably wondering why I'm using a custom SVG for the Bluesky icon rather than
  // just importing it from the Font Awesome React library like the other libraries. Well,
  // I'm surprised that you don't remember the hours you spent on a Monday trying to do
  // what seemed like a simple version bump to get to a version of FA that included the
  // Bluesky icon, but struggling to even figure out why React was complaining about every
  // single Font Awesome import being `undefined`.
  //
  // You eventually found https://stackoverflow.com/q/52376720 which gave the explanation
  // that for some reason Webpack doesn't like some new version of Font Awesome, and that
  // while it _does_ work after a `yarn build`, it doesn't work in development mode, and
  // you decided that this was not an acceptable tradeoff.
  //
  // After that, you tried just importing the CSS and using manual classes for all Font
  // Awesome icons, but that was a pain due to using a bunch of size parameters and other
  // nice properties of the `FontAwesomeIcon` component (like `spin` for the refresher).
  //
  // So you decided to learn a bit about SVGs in JSX, downloaded the Bluesky SVG from the
  // Font Awesome download package, and hand-modified it to match what the other icons
  // are doing for free.
  if (isBluesky) {
    faIcon = <svg aria-hidden="true" data-prefix="fas" data-icon="bluesky" className="svg-inline--fa fa-bluesky fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M123.6 34.5c66.4 50.1 137.9 151.5 164.2 206C314 186 385.5 84.5 452 34.5c48-36.1 125.6-64.1 125.6 24.9c0 17.8-10.1 149.2-16.1 170.5c-20.7 74.2-96.1 93.1-163.1 81.6c117.2 20 147 86.3 82.6 152.6C358.7 590 305.2 432.5 291.5 392.1c-2.5-7.5-3.7-10.9-3.7-7.9c0-3.1-1.2 .4-3.7 7.9C270.4 432.5 216.9 590 94.6 464.1C30.2 397.8 60 331.5 177.2 311.5C110.2 322.9 34.8 304 14.1 229.8C8.1 208.5-2 77.1-2 59.3c0-88.9 77.7-61 125.6-24.9z"/></svg>
  } else {
    faIcon = <FontAwesomeIcon icon={ icon } />
  }

  let outLink
  if (internal != null) {
    outLink = <Link to={ internal }>{ faIcon }</Link>
  } else {
    outLink = <a href={ link } rel='noreferrer noopener' target='_blank'>{ faIcon }</a>
  }

  return (
    <Tooltip title={ text } arrow size="small" duration={ 100 } trigger="mouseenter">
      { outLink }
    </Tooltip>
  )
}

FooterIcon.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  link: PropTypes.string,
  internal: PropTypes.string,
  isBluesky: PropTypes.boolean,
}

const Footer = () => (
  <footer className={ styles.footer }>
    <FooterIcon icon={ faQuestionCircle } text='About/FAQ' internal="/about" />
    <FooterIcon isBluesky={ true } text='@zeal.gg' link='https://bsky.app/profile/zeal.gg'/>
    <FooterIcon icon={ faReddit } text='/r/zealgg' link='https://reddit.com/r/zealgg' />
    <FooterIcon icon={ faPatreon } text='Patreon' internal="/patreon" />
  </footer>
)

class App extends Component {
  render() {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
    })

    return (
      <Router>
        <div className={ styles.app }>
          <Switch>
            <Route exact path="/" component={ Landing } />
            <Route component={ Header } />
          </Switch>

          <div className={ styles.content }>
            <Switch>
              <Route exact path="/" component={ null } />
              <Route path="/about" component={ About } />
              <Route path="/patreon" component={ Patreon } />
              <Route path="/leaderboard/:slug" component={ Leaderboard } />
              <Route path="/:region/:name" component={ Summoner } />
              <Route path="/:slug" component={ Leaderboard } />
            </Switch>
          </div>

          <Switch>
            <Route path="/about" component={ Disclaimer } />
          </Switch>

          <Route component={ Footer } />
        </div>
      </Router>
    )
  }
}

export default App
