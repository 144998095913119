// Maps service platforms to service regions.
// https://developer.riotgames.com/regional-endpoints.html
export const PLATFORMS = {
  br1: 'BR',
  eun1: 'EUNE',
  euw1: 'EUW',
  jp1: 'JP',
  kr: 'KR',
  la1: 'LAN',
  la2: 'LAS',
  na: 'NA',
  na1: 'NA',
  oc1: 'OCE',
  tr1: 'TR',
  ru: 'RU',
}

export const SEASON_2020_START = new Date('2020-01-10')
export const SEASON_2022_START = new Date('2022-01-06')
export const SEASON_2024_START = new Date('2024-01-10')
export const SEASON_2025_START = new Date('2025-01-09')

export const REGIONS = {
  br: 'br1',
  eune: 'eun1',
  euw: 'euw1',
  jp: 'jp1',
  kr: 'kr',
  lan: 'la1',
  las: 'la2',
  na: 'na1',
  oce: 'oc1',
  tr: 'tr1',
  ru: 'ru',
  pbe: 'pbe1',
}

export const DIVS_PER_TIER = 4

export const TIER_OFFSETS = {
  IRON: 0,
  BRONZE: 1,
  SILVER: 2,
  GOLD: 3,
  PLATINUM: 4,
  EMERALD: 5,
  DIAMOND: 6,
  MASTER: 7,
  GRANDMASTER: 7,
  CHALLENGER: 7,
}

export const OP_GG_REGIONS = {
  br: 'br',
  br1: 'br',
  eun1: 'eune',
  eun: 'eune',
  euw1: 'euw',
  euw: 'euw',
  jp: 'jp',
  jp1: 'jp',
  kr: 'www',
  kr1: 'www',
  la1: 'lan',
  la2: 'las',
  na: 'na',
  na1: 'na',
  oc: 'oce',
  oce: 'oce',
  oc1: 'oce',
  tr: 'tr',
  tr1: 'tr',
  ru: 'ru',
  ru1: 'ru',
}

export const SCALE_MAP = {
  '1m': 31,
  '2m': 62,
  '3m': 93,
  'max': 365,
}
