import React from 'react'
import { Helmet } from 'react-helmet'

import styles from './Patreon.module.css'

const patreon = <a href="https://www.patreon.com/zealgg" rel="noreferrer noopener" target="_blank"><strong>Patreon page</strong></a>

const Patreon = () => (
  <div className={ styles.patreon }>
    <Helmet><title>Patreon | zeal.gg</title></Helmet>
    <div>
      <h1>Patreon</h1>
      <p>
        I created <strong>zeal.gg</strong> back in 2018 as a passion project because it was something
        that I wanted to exist as a fan of League of Legends, yet nobody had made it yet. I'm amazed
        that several years later, people are still using the site to compete with their friends, and
        some streamers have even checked it out to run their own climb challenges.
      </p>

      <p>
        I don't run ads on <strong>zeal.gg</strong> because I hate the bloat they add to the web.
        If you enjoy using the site and would like to support its development and hosting costs,
        I've set up a completely optional { patreon } that you can join.
      </p>
        
      <p>
        For subscribing, I'll set up a leaderboard of your choice to automatically refresh hourly.
        At the higher tiers, I'll also add a vanity URL (like <strong>zeal.gg/cool-cats</strong>)
        for your page!
      </p>

      <p>
        Regardless of whether or not you're able to support the site financially, thank you so much
        for continuing to use <strong>zeal.gg</strong>!
      </p>
    </div>
  </div>
)

export default Patreon
